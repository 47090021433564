"use client";

import { cn } from "@everfund/ui";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { Route } from "next";
import { useEffect, useRef } from "react";

import { Link } from "~/navigation";

import { AccordionTrigger } from "./Accordian";

interface LottieButtonProps {
  active: boolean;
  animationData: any;
  disabled?: boolean;
  hasMenu?: boolean;
  open?: boolean;
  title: string;
  to?: Route;
}

export const LottieButton = ({
  active,
  animationData,
  disabled,
  hasMenu,
  title,
  to,
}: LottieButtonProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleMouseEnter = () => {
      lottieRef.current?.play();
    };

    const handleMouseLeave = () => {
      lottieRef.current?.stop();
    };

    anchorRef.current?.addEventListener("mouseenter", handleMouseEnter);
    anchorRef.current?.addEventListener("mouseleave", handleMouseLeave);
    buttonRef.current?.addEventListener("mouseenter", handleMouseEnter);
    buttonRef.current?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      anchorRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      anchorRef.current?.removeEventListener("mouseleave", handleMouseLeave);
      buttonRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      buttonRef.current?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  if (!hasMenu && to) {
    return (
      <Link
        aria-disabled={disabled}
        className={cn(
          active
            ? "dark:bg-nord-3 bg-gray-50"
            : "dark:hover:bg-nord-3 hover:bg-gray-50",
          "dark:text-primary w-full gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50",
          "flex flex-1 items-center justify-between font-medium transition-all [&[data-state=open]>svg]:rotate-180",
          disabled && !active && "cursor-not-allowed opacity-50",
        )}
        href={disabled ? {} : to}
        ref={anchorRef}
        tabIndex={disabled ? -1 : undefined}
      >
        <div className="flex items-center gap-x-3">
          <Lottie
            animationData={animationData}
            autoplay={false}
            className={cn("lottie-icon h-6 w-6", active && "active")}
            lottieRef={lottieRef}
          />
          {title}
        </div>
      </Link>
    );
  }

  return (
    <AccordionTrigger
      className={cn(
        active
          ? "dark:bg-nord-3 bg-gray-50"
          : "dark:hover:bg-nord-3 hover:bg-gray-50",
        "dark:text-primary w-full gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50",
        disabled && !active && "cursor-not-allowed opacity-50",
      )}
      ref={buttonRef}
    >
      <div className="flex items-center gap-x-3">
        <Lottie
          animationData={animationData}
          autoplay={false}
          className={cn("lottie-icon h-6 w-6", active && "active")}
          lottieRef={lottieRef}
        />
        {title}
      </div>
    </AccordionTrigger>
  );
};
