import { cn } from "@everfund/ui";
import { Switch } from "@headlessui/react";
import { useTranslations } from "next-intl";
import { useState } from "react";

import { useUser } from "~/context/user";
import useSetMode from "~/requests/mutations/setMode";

interface LiveModeSwitchProps {
  disabled?: boolean;
}

export const LiveModeSwitch = ({ disabled }: LiveModeSwitchProps) => {
  const { user } = useUser();

  const setMode = useSetMode();

  const [uiLiveMode, setUiLiveMode] = useState(user?.liveMode ?? false);
  const t = useTranslations("layouts.components.LiveModeSwitch");

  const toggleLiveMode = async () => {
    setUiLiveMode(!uiLiveMode);
    setMode.mutate({
      liveMode: !uiLiveMode,
    });
  };

  return (
    <div>
      <Switch.Group
        as="div"
        className="flex items-center justify-between p-2 font-medium"
      >
        <Switch.Label className="mr-4 cursor-pointer text-base text-secondary md:text-xs">
          {t("testMode")}
        </Switch.Label>
        <Switch
          checked={!uiLiveMode}
          className={cn(
            "dark:ring-offset-nord-3 relative inline-flex h-6 w-11 shrink-0 rounded-full border-2 border-transparent ring-offset-white transition focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2",
            !uiLiveMode ? `!bg-yellow-600` : `dark:bg-nord-1 bg-gray-200`,
            disabled && "cursor-pointer opacity-50",
          )}
          disabled={disabled}
          onChange={() => toggleLiveMode()}
        >
          <span
            aria-hidden="true"
            className={cn(
              "dark:bg-nord-4 inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition",
              !uiLiveMode ? `translate-x-5` : `translate-x-0`,
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};
