import { cn } from "@everfund/ui";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import { ReactElement } from "react";

import {
  bgTailwind,
  iconBorder,
  sizeTailwind,
  sizeTextTailwind,
} from "./styled";

interface Props {
  avatarUrl?: null | string;
  bgColor?: "blue" | "heart" | "lilac";
  bgHex?: string;
  firstName?: null | string;
  lastName?: null | string;
  size?: "base" | "lg" | "sm" | "xl" | "xs";
}

const SIZE_MAP = {
  base: 256,
  lg: 336,
  sm: 176,
  xl: 416,
  xs: 96,
};

const Avatar = ({
  avatarUrl,
  bgColor = "blue",
  bgHex,
  firstName,
  lastName,
  size,
}: Props): ReactElement => {
  if (avatarUrl && avatarUrl !== "https://www.gravatar.com/avatar?d=mp") {
    return (
      <Image
        alt="user avatar"
        className={cn("inline-block rounded-full", sizeTailwind(size))}
        height={SIZE_MAP[size || "base"]}
        src={avatarUrl}
        width={SIZE_MAP[size || "base"]}
      />
    );
  }

  if (!firstName) {
    return (
      <>
        <span
          className={cn(
            "inline-flex items-center justify-center overflow-hidden rounded-full",
            bgTailwind(bgColor),
            sizeTailwind(size),
            iconBorder(bgColor),
          )}
          style={{
            backgroundColor: bgHex,
          }}
        >
          <FontAwesomeIcon
            className="mt-2 size-full text-white"
            icon={faUser}
          />
        </span>
      </>
    );
  }

  const matches = firstName.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = matches?.join(""); // JSON

  return (
    <span
      className={cn(
        "inline-flex items-center justify-center overflow-hidden rounded-full",
        bgTailwind(bgColor),
        sizeTailwind(size),
      )}
      style={{
        backgroundColor: bgHex,
      }}
    >
      <span
        className={cn(
          "font-medium leading-none text-white",
          sizeTextTailwind(size),
        )}
      >
        {acronym?.toUpperCase()}
        {lastName && lastName.charAt(0).toUpperCase()}
      </span>
    </span>
  );
};

export default Avatar;
