"use client";

import { Permission } from "@everfund/types";
import { Route } from "next";
import { useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";
import { use, useMemo } from "react";

import { useOrganizations } from "~/context/organizations";
import { useHasPermission } from "~/hooks/useHasPermission";
import { usePathname } from "~/navigation";
import routes from "~/utils/routes";

import LottieCardJson from "./animations/card.json";
import LottieFundraisingJson from "./animations/fundraising.json";
import LottieHomeJson from "./animations/home.json";
import LottieIntegrationsJson from "./animations/integration.json";
import LottieReportsJson from "./animations/reports.json";
import LottieSettingsJson from "./animations/settings.json";
import LottieSupportersJson from "./animations/supporters.json";

export type NavCategory = {
  active: boolean;
  isVisible: boolean;
  lottieIcon: object;
  menu?: {
    active: boolean;
    isVisible?: boolean;
    title: string;
    to: Route | Route<any>;
  }[];
  title: string;
  to?: Route;
};

const visibleCategory = (
  hasPermission: (permission: Permission) => boolean,
  permissions: Permission[],
) => {
  return permissions.some((permission) => hasPermission(permission));
};

export const useNavigationMenus = () => {
  const pathname = usePathname();
  const params = useSearchParams();
  const { organizations } = useOrganizations();
  const { hasPermission } = useHasPermission();
  const t = useTranslations("layouts.components.NavigationMenu");

  const navigationMenu = useMemo<NavCategory[]>(
    () => [
      {
        active: pathname.split("/").length === 1,
        isVisible: true,
        lottieIcon: LottieHomeJson,
        title: t("home"),
        to: routes.home,
      },
      {
        active: pathname?.split("/")[1] === "donations",
        isVisible: visibleCategory(hasPermission, ["payments:read"]),
        lottieIcon: LottieCardJson,
        menu: [
          {
            active: params?.get("view") === "single",
            isVisible: hasPermission("payments:read"),
            title: t("singleDonations"),
            to: routes.donations.list({ status: "succeeded", view: "single" }),
          },
          {
            active: params?.get("view") === "recurring",
            isVisible: hasPermission("payments:read"),
            title: t("recurringDonations"),
            to: routes.donations.list({
              status: "succeeded",
              view: "recurring",
            }),
          },
          {
            active: params?.get("view") === "giftaid",
            isVisible:
              (organizations?.records ?? []).some(
                (org) => org.primaryCountry === "GB",
              ) && hasPermission("payments:read"),
            title: t("giftAid"),
            to: routes.donations.list({ status: "unclaimed", view: "giftaid" }),
          },
          {
            active: params?.get("view") === "marketing",
            isVisible: hasPermission("payments:read"),
            title: t("marketing"),
            to: routes.donations.list({ status: "any", view: "marketing" }),
          },
          {
            active: pathname?.split("/")[1] === "subscriptions",
            isVisible: hasPermission("subscriptions:read"),
            title: t("subscriptions"),
            to: routes.donations.subscriptions("active"),
          },
          {
            active: pathname?.split("/")[1] === "payouts",
            isVisible: hasPermission("payouts:read"),
            title: t("payouts"),
            to: routes.donations.payouts,
          },
        ],
        title: t("donations"),
      },
      {
        active: pathname?.split("/")[1] === "reports",
        isVisible: visibleCategory(hasPermission, [
          "payments:read",
          "transactions:read",
          "campaigns:read",
          "channels:read",
        ]),
        lottieIcon: LottieReportsJson,
        title: t("reports"),
        to: routes.reports.new,
      },
      {
        active: pathname?.split("/")[1] === "supporters",
        isVisible: hasPermission("customers:read"),
        lottieIcon: LottieSupportersJson,
        title: t("supporters"),
        to: routes.supporters,
      },
      {
        active: pathname?.split("/")[1] === "fundraising",
        isVisible: visibleCategory(hasPermission, [
          "campaigns:read",
          "channels:read",
          "templates:read",
          "goals:read",
          "customFields:read",
        ]),
        lottieIcon: LottieFundraisingJson,
        menu: [
          {
            active: pathname?.split("/")[2] === "campaigns",
            isVisible: hasPermission("campaigns:read"),
            title: t("campaigns"),
            to: routes.fundraising.campaigns,
          },
          {
            active: pathname?.split("/")[2] === "channels",
            isVisible: hasPermission("channels:read"),
            title: t("channels"),
            to: routes.fundraising.channels,
          },
          {
            active:
              pathname?.split("/")[2] === "templates" &&
              pathname?.split("/").length == 3,
            isVisible: hasPermission("templates:read"),
            title: t("templates"),
            to: routes.fundraising.templates,
          },
          {
            active: pathname?.split("/")[2] === "goals",
            isVisible: hasPermission("goals:read"),
            title: t("goals"),
            to: routes.fundraising.goals,
          },
          {
            active: pathname?.split("/")[2] === "customFields",
            isVisible: hasPermission("customFields:read"),
            title: t("customFields"),
            to: routes.fundraising.customFields,
          },
        ],
        title: t("fundraising"),
      },
      {
        active: pathname?.split("/")[1] === "integrations",
        isVisible: visibleCategory(hasPermission, ["endpoints:read"]),
        lottieIcon: LottieIntegrationsJson,
        menu: [
          {
            active: pathname?.split("/")[2] === "webhook",
            isVisible: hasPermission("endpoints:read"),
            title: t("webhooks"),
            to: routes.integrations.webhooks,
          },
        ],
        title: t("integrations"),
      },
      {
        active: pathname?.split("/")[1] === "settings",
        isVisible: visibleCategory(hasPermission, [
          "organizations:read",
          "users:read",
          "auditLogs:read",
        ]),
        lottieIcon: LottieSettingsJson,
        menu: [
          {
            active: pathname?.split("/")[2] === "organization",
            isVisible: hasPermission("organizations:read"),
            title: t("organizations"),
            to: routes.settings.organizations,
          },
          {
            active: pathname?.split("/")[2] === "team-members",
            isVisible: hasPermission("users:read"),
            title: t("teamMembers"),
            to: routes.settings.teamMembers,
          },
          {
            active: pathname?.split("/")[2] === "logs",
            isVisible: hasPermission("auditLogs:read"),
            title: t("auditLogs"),
            to: routes.settings.auditLogs,
          },
        ],
        title: t("settings"),
      },
    ],
    [params, pathname, organizations],
  );

  return navigationMenu;
};
