"use client";

import { faMoon, faSunBright } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";

import useIsClient from "~/hooks/useIsClient";

import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "./Dropdown";

export const ApperanceMenu = () => {
  const { resolvedTheme, setTheme } = useTheme();
  const t = useTranslations("layouts.components.AppearanceMenu");
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }
  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <span className="capitalize">
            {t("apperance")}: {t(resolvedTheme.toLowerCase())}{" "}
          </span>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent>
            <DropdownMenuItem
              className="flex flex-row gap-2"
              onSelect={() => setTheme("light")}
            >
              <FontAwesomeIcon className="size-4" icon={faSunBright} />
              <span>{t("light")}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex flex-row gap-2"
              onSelect={() => setTheme("dark")}
            >
              <FontAwesomeIcon className="size-4" icon={faMoon} />
              <span>{t("dark")}</span>
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  );
};

export default ApperanceMenu;
